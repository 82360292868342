import React from "react"

const MatchPackCard = ({ data }) => {
  const PackData = data
  return (
    <div className="pricing">
      {PackData.map(({ pack, price }, index) => (
        <section className="lbid_date mar-2" key={index}>
          <ul className={`fill-gray${index % 2 ? 1 : 0}`}>
            <li>
              <h5 className="text-right pack-count">
                {pack}
                <span className="pack-name">Matches pack</span>
              </h5>
            </li>
            <li>
              <h5 className="text-right pack-price">{price}</h5>
            </li>
          </ul>
        </section>
      ))}
    </div>
  )
}

export default MatchPackCard
