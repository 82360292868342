export const BasicFeatureData = {
  posts: [
    {
      // title: "Latest technology",
      // content: "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      lists: [
        {
          item: "Invite other users to a club",
        },
        {
          item: "User can be part of multiple clubs",
        },
        {
          item: "Different user roles - admin, auditor, scorer, commentator",
        },
        {
          item: "Main dashboard for quick information",
        },
        {
          item: "Comprehensive Event Detail view",
        },
        {
          item: "Detailed Event Stats",
        },
        {
          item: "Easy to add teams to matches",
        },
        {
          item: "Event Best performances",
        },
        {
          item: "Event Toppers",
        },
        {
          item: "Event News",
        },
      ],
    },
    {
      // title: "Awesome Support",
      // content: "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      lists: [
        {
          item: "Comprehensive Match Detail view",
        },
        {
          item: "Match scoreboard",
        },
        {
          item: "T20, ODI, 3-5 day Test support",
        },
        {
          item: "Easy Super over entry for T20/ODI",
        },
        {
          item: "Edit almost any match info",
        },
        {
          item: "Notifications for 4s, 6s and Wickets",
        },
        {
          item: "Notifications for key plays",
        },
        {
          item: "Notifications for toss updates",
        },
        {
          item: "Notifications for player of the match",
        },
        {
          item: "Realtime Score updates",
        },
      ],
    },
    {
      // title: "Cloud Technology",
      // content: "There are many variations of passages of lorem ipsum available, but the majority have  suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. ",
      lists: [
        {
          item: "QR code download for Event/Match",
        },
        {
          item: "Chat support between scorers",
        },
        {
          item: "Social login with Google Id",
        },
        {
          item: "Social login with Apple Id",
        },
        {
          item: "Event external portal support",
        },
      ],
    },
  ],
};