import React from "react"
import { Container, Row, Col } from "react-bootstrap"
// import PricingPlanCard from "@/components/pricing-plan-card";
import MatchPackCard from "@/components/match-pack-card"
import { MatchPackData } from "./data"
import PlanIcon from "../../images/plan.svg"

const MatchPack = () => {
  return (
    <section className="pricingSection">
      <Container>
        <span>
          <h3 className="text-center">Pay As You Grow</h3>
        </span>
        <Row className="justify-content-md-center pos-rel">
          <Col lg={12} md={12} className="plans-pricing">
            <Col lg={5} md={12} className="pricing-cont">
              <img src={PlanIcon} className="plan-icn" alt="icon"></img>
              <h5>Add-on Match Packs</h5>
              <p>
                If your monthly plans are not sufficient and you want to access
                more matches, you can buy the following packs which get added to
                the monthly quota.{" "}
              </p>
              <p>
                These can be set up as auto top-up whenever your matches go
                below a certain threshold.{" "}
              </p>
            </Col>
            <div></div>
            <Col lg={7} md={12} className="txt-center">
              <MatchPackCard data={MatchPackData} />
            </Col>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default MatchPack
