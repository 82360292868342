export const PricingPlanData = [
  {
    title: "BRONZE",
    currency: "",
    price: "FREE",
    text: "10 matches",
    text1: " every month",
    // small: "per month",
    // tag: "Most Popular",
    url: "/service-details",
  },
  {
    title: "SILVER",
    currency: "$",
    price: 9.99,
    text: "50 matches",
    text1: " every month",
    small: "per month",
    // tag: "Most Popular",
    url: "/service-details",
  },
  {
    title: "GOLD",
    currency: "$",
    price: 29.99,
    text: "250 matches",
    text1: " every month",
    small: "per month",
    tag: "Most Popular",
    url: "/service-details",
  },
  {
    title: "PLATINUM",
    currency: "$",
    price: 99.99,
    text: "1000 matches",
    text1: " every month",
    small: "per month",
    // tag: "Most Popular",
    url: "/service-details",
  },
]
