import React from "react";

const BasicFeatureCard = ({ data }) => {
    // const BasicFeatureData = data;
    return (
        <div className="commonSection fill-color pad-10 card-min-height">
            <p>{data.title}</p>
            <ul className="pricing-ul">
                {data.lists.map(({ item }, index) => (
                    <li key={index}>
                        {item}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default BasicFeatureCard;
