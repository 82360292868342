// import blogS1 from "@/images/blog/11.jpg";
// import blogS2 from "@/images/blog/12.jpg";
// import blogS3 from "@/images/blog/13.jpg";

export const MatchPackData = [
  {
    pack: "25",
    price: "$4.99",
    // title: "basic rules of running web agency",
    // image: blogS1,
    // url: "/blog-single",
  },
  {
    pack: "75",
    price: "$7.99",
    // title: "basic rules of running web agency",
    // image: blogS2,
    // url: "/blog-single",
  },
  {
    pack: "100",
    price: "$14.99",
    // title: "basic rules of running web agency",
    // image: blogS3,
    // url: "/blog-single",
  },

  {
    pack: "500",
    price: "$69.99",
    // title: "basic rules of running web agency",
    // image: blogS3,
    // url: "/blog-single",
  },
]
