import React, { useState } from "react"
import { Container, Row, Col } from "react-bootstrap"
import PricingPlanCard from "@/components/pricing-plan-card"
import { PricingPlanData } from "./data"
import ButtonGroup from "react-bootstrap/ButtonGroup"
import ToggleButton from "react-bootstrap/ToggleButton"

const PricingPlan = () => {
  // const [checked, setChecked] = useState(false)
  const [radioValue, setRadioValue] = useState("1")
  const pricingDetails = useState(JSON.parse(JSON.stringify(PricingPlanData)))

  const radios = [
    { name: "Monthly", value: "1" },
    { name: "Annual", value: "2" },
  ]

  function setRadioValueState(data) {
    setRadioValue(data)
    pricingDetails[0].forEach(function (a, index) {
      if (a.currency !== "") {
        if (parseInt(data) === 2) {
          a.price = (a.price - (a.price * 10) / 100).toFixed(2)
        } else {
          a.price = PricingPlanData[index].price
        }
      }
    })
  }
  return (
    <section className="service_section_2 commonSection">
      <Container>
        <Row className="justify-content-md-center toggle-mobile">
          <ButtonGroup className="toggl-btn">
            {radios.map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                variant={idx % 2 ? "outline-success" : "outline-danger"}
                name="radio"
                value={radio.value}
                checked={radioValue === radio.value}
                onChange={e => setRadioValueState(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))}
            <span className="offer-tag ">10% Off</span>
          </ButtonGroup>
        </Row>
        <Row className="justify-content-md-center">
          {pricingDetails[0].map((post, index) => (
            <Col
              lg={3}
              md={6}
              xs={6}
              key={index}
              className="txt-center package-box"
            >
              <PricingPlanCard data={post} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default PricingPlan
