import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import BasicFeatureCard from "@/components/basic-feat-card"

import { BasicFeatureData } from "./data"

const BasicFeature = () => {
  const { posts } = BasicFeatureData
  return (
    <section className="commonSection">
      <Container>
        <h4 id="features" className="text-center">
          FEATURES FOR ALL PLANS
        </h4>
        <Row className="justify-content-md-center">
          {posts.map((post, index) => (
            <Col lg={4} md={6} key={index}>
              <BasicFeatureCard data={post} />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default BasicFeature
